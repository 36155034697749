import '@/styles/globals.css'
import 'tailwindcss/tailwind.css'

import { datadogRum } from '@datadog/browser-rum'
import { AppProps } from 'next/app'
import { HubspotProvider } from 'next-hubspot'
import React from 'react'

datadogRum.init({
  applicationId: `${process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID}`,
  clientToken: `${process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN}`,
  site: 'datadoghq.com',
  service: 'shiftlab.co-website',
  env: `${process.env.NODE_ENV}`,
  version: '1.0.0',
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
})

datadogRum.startSessionReplayRecording()

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <HubspotProvider>
      <Component {...pageProps} />
    </HubspotProvider>
  )
}

export default MyApp
